export default [
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "やわらかいまどろみの中で",
        "filename": "tenderly_drowzy.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "Quiet Night",
        "filename": "quiet_night.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "思い出と深呼吸",
        "filename": "memory_and_deep_breath.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "花散ル風",
        "filename": "sakuratirukaze.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "静かな森",
        "filename": "quiet_forest.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "哀愁と憂いの春に",
        "filename": "sorrow_and_melancholy_spring.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "ピアノ",
            "穏やか",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "切ない風に吹かれて…",
        "filename": "blowing_in_the_nostalgic_wind.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "今日の日に、おやすみ",
        "filename": "good_night_today.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "きらめく世界",
        "filename": "shimmering_world.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "優しく、揺れる…",
        "filename": "gently_swinging.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "悲哀の響き",
        "filename": "echo_with_sorrow.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "ピアノ",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "name": "Moving Wind",
        "filename": "moving_wind.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "ピアノ",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "name": "Mortal Coil",
        "filename": "mortal_coil.mp3",
        "author": "shimtone",
        "tags": [
            "激しい",
            "戦闘"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "name": "Dull Color City",
        "filename": "dull_color_city.mp3",
        "author": "shimtone",
        "tags": [
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "name": "Power Drifting",
        "filename": "power_drifting.mp3",
        "author": "shimtone",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "線香花火",
        "filename": "senkouhanabi.mp3",
        "author": "shimtone",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Slope Point",
        "filename": "slope_point.mp3",
        "author": "shimtone",
        "tags": [
            "激しい",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "赤い月の朝",
        "filename": "akaitukinoasa.mp3",
        "author": "Motoyuki",
        "tags": [
            "ピアノ",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "海辺の夕暮れ",
        "filename": "umibenoyugure.mp3",
        "author": "Motoyuki",
        "tags": [
            "穏やか",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "振り返る過去",
        "filename": "hurikaerukako.mp3",
        "author": "Motoyuki",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "別れと笑顔",
        "filename": "wakaretoegao.mp3",
        "author": "Motoyuki",
        "tags": [
            "ピアノ",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "旅がらす",
        "filename": "tabigarasu.mp3",
        "author": "MAKOOTO",
        "tags": [
            "穏やか",
            "前向き",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "SUNSET BEACH BAR",
        "filename": "sunset_beach_bar.mp3",
        "author": "MAKOOTO",
        "tags": [
            "穏やか",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "獄門鬼・羅刹",
        "filename": "gokumonki.mp3",
        "author": "MAKOOTO",
        "tags": [
            "激しい",
            "戦闘",
            "ホラー",
            "緊張感",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Dark Survival",
        "filename": "dark_survival.mp3",
        "author": "MAKOOTO",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "泣カナイデ",
        "filename": "nakanaide.mp3",
        "author": "まんぼう二等兵",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "まどろむ時間",
        "filename": "madoromuzikan.mp3",
        "author": "まんぼう二等兵",
        "tags": [
            "穏やか",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "雪月花",
        "filename": "setugekka.mp3",
        "author": "まんぼう二等兵",
        "tags": [
            "悲しい",
            "前向き",
            "感動",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Etude Op.92",
        "filename": "etude_op.92.mp3",
        "author": "KK",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "閉ざした心",
        "filename": "tozashitakokoro.mp3",
        "author": "KK",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "接触",
        "filename": "sessyoku.mp3",
        "author": "KK",
        "tags": [
            "ホラー",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "（環境音）海",
        "filename": "see.mp3",
        "author": "VSQ",
        "tags": [
            "穏やか",
            "環境音"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "（環境音）雨",
        "filename": "rain.mp3",
        "author": "効果音ラボ",
        "tags": [
            "穏やか",
            "環境音",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "（環境音）小川",
        "filename": "ogawa.mp3",
        "author": "効果音ラボ",
        "tags": [
            "穏やか",
            "環境音"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "（環境音）河原",
        "filename": "kawara.mp3",
        "author": "効果音ラボ",
        "tags": [
            "穏やか",
            "環境音"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "（環境音）春の山",
        "filename": "harunoyama.mp3",
        "author": "効果音ラボ",
        "tags": [
            "穏やか",
            "環境音"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "（環境音）夏の山",
        "filename": "natunoyama.mp3",
        "author": "効果音ラボ",
        "tags": [
            "穏やか",
            "環境音",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "呪縛",
        "filename": "zyubaku.mp3",
        "author": "SENTIVE",
        "tags": [
            "激しい",
            "歌あり",
            "悲しい",
            "感動",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "呪縛\"Re\"",
        "filename": "zyubaku_re.mp3",
        "author": "SENTIVE",
        "tags": [
            "激しい",
            "歌あり",
            "悲しい",
            "感動",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "百鬼夜抄",
        "filename": "hyakkiyasyou.mp3",
        "author": "Notzan ACT",
        "tags": [
            "激しい",
            "歌あり",
            "前向き",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "Ancient White",
        "filename": "ancient_white.mp3",
        "author": "ISAo",
        "tags": [
            "激しい",
            "歌あり",
            "前向き",
            "感動",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "Shining star",
        "filename": "shining_star.mp3",
        "author": "カワサキヤスヒロ",
        "tags": [
            "歌あり",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "朝焼けのキセキ ",
        "filename": "asayakenokiseki.mp3",
        "author": "カワサキヤスヒロ",
        "tags": [
            "歌あり",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "邪の花びら触るわせ",
        "filename": "zyanohana.mp3",
        "author": "風可＆葉羽",
        "tags": [
            "穏やか",
            "歌あり",
            "悲しい",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "あなたに恋をしなければ ",
        "filename": "anatanikoiwosinakereba.mp3",
        "author": "龍崎一",
        "tags": [
            "穏やか",
            "歌あり",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "He's Gone",
        "filename": "hes_gone.mp3",
        "author": "ISAo",
        "tags": [
            "穏やか",
            "悲しい",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "散花",
        "filename": "sanka.mp3",
        "author": "shimtone",
        "tags": [
            "穏やか",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "The Assembly_Area05",
        "filename": "the_assembly_area05.mp3",
        "author": "ISAo",
        "tags": [
            "ホラー",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Enemy Waves_Area04",
        "filename": "enemy_waves_area04.mp3",
        "author": "ISAo",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Tsudzumi JAPAN",
        "filename": "tsudumi-japan.mp3",
        "author": "SHW",
        "tags": [
            "前向き",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Tsudzumi JAPAN 3",
        "filename": "tsudzumi-japan3.mp3",
        "author": "SHW",
        "tags": [
            "前向き",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Akatsuki JAPAN",
        "filename": "akatsuki-japan.mp3",
        "author": "SHW",
        "tags": [
            "激しい",
            "戦闘",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Ikusa JAPAN",
        "filename": "ikusa.mp3",
        "author": "SHW",
        "tags": [
            "激しい",
            "前向き",
            "感動",
            "戦闘",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "柏木",
        "filename": "kashiwagi.mp3",
        "author": "SHW",
        "tags": [
            "穏やか",
            "悲しい",
            "前向き",
            "感動",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Inaho-JAPAN",
        "filename": "inaho-japan.mp3",
        "author": "SHW",
        "tags": [
            "穏やか",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Nostalgia",
        "filename": "nostalgia.mp3",
        "author": "OtoLogic",
        "tags": [
            "穏やか",
            "オルゴール",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "過ちの代償",
        "filename": "ayamachi_no_daisho.mp3",
        "author": "OtoLogic",
        "tags": [
            "穏やか",
            "オルゴール",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "SweetShareRooM",
        "filename": "sweetshareroom.mp3",
        "author": "Flehmann",
        "tags": [
            "歌あり",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "千歳の如く",
        "filename": "titosenogotoku.mp3",
        "author": "すもち",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "memories",
        "filename": "memories.mp3",
        "author": "鎌田優紀子",
        "tags": [
            "穏やか",
            "ピアノ",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "幻想花",
        "filename": "gensouka.mp3",
        "author": "Notzan ACT",
        "tags": [
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "また逢いましょう",
        "filename": "mataaimasyou.mp3",
        "author": "松浦洋介",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Mechanical",
        "filename": "mechanical.mp3",
        "author": "Low",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Escape Mayhem",
        "filename": "escape_mayhem.mp3",
        "author": "ISAo",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感",
            "ホラー"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "迅雷宝刀乱舞",
        "filename": "zinraihoutoutanbu.mp3",
        "author": "ISAo",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感",
            "ホラー",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Break the Shell",
        "filename": "break_the_shell.mp3",
        "author": "オオヒラセイジ",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "ずぶぬれレインコート",
        "filename": "zubunurereincort.mp3",
        "author": "オオヒラセイジ",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "夕暮れの約束",
        "filename": "yuugurenoyakusoku.mp3",
        "author": "Make a field Music",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "戦火を超えて",
        "filename": "senkawokoete.mp3",
        "author": "スエノブ",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Electric Highway",
        "filename": "electric_highway.mp3",
        "author": "チョコミント",
        "tags": [
            "激しい",
            "前向き",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "情動カタルシス",
        "filename": "zyoudou_katarusisu.mp3",
        "author": "まんぼう二等兵",
        "tags": [
            "激しい",
            "前向き",
            "感動",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Hard Puncher",
        "filename": "hard_puncher.mp3",
        "author": "shimtone",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "FIREARMS",
        "filename": "firearms.mp3",
        "author": "shimtone",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "弾む思いと未来志向的なBGM",
        "filename": "hazumuomoito.mp3",
        "author": "鷹尾まさき",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "霧中の城と囚われの姫的なBGM",
        "filename": "mutyuunosiroto.mp3",
        "author": "鷹尾まさき",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "わくわくスイーツタイム的なBGM",
        "filename": "wakuwaku_sweets_time.mp3",
        "author": "鷹尾まさき",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "懐古に浸る的なBGM",
        "filename": "kaikonihitaru.mp3",
        "author": "鷹尾まさき",
        "tags": [
            "穏やか",
            "ピアノ",
            "悲しい",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "桜城にて",
        "filename": "sakurazyounite.mp3",
        "author": "マニーラ",
        "tags": [
            "穏やか",
            "悲しい",
            "前向き",
            "感動",
            "和風"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "静かなる闘志",
        "filename": "sizukanarutoushi.mp3",
        "author": "風人",
        "tags": [
            "激しい",
            "前向き",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "春の兆し(Signs of spring)",
        "filename": "signs_of_spring.mp3",
        "author": "蒲鉾さちこ",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Surviving Cyber",
        "filename": "surviving_cyber.mp3",
        "author": "Make a field Music",
        "tags": [
            "激しい",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Warning Beat",
        "filename": "warning_beat.mp3",
        "author": "風人",
        "tags": [
            "激しい",
            "前向き",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "遠くの朝 (ピアノメロ ver.)",
        "filename": "tookunoasa.mp3",
        "author": "hotaru sounds",
        "tags": [
            "穏やか",
            "ピアノ",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "2:23 AM",
        "filename": "2_23_am.mp3",
        "author": "しゃろう",
        "tags": [
            "穏やか",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "ナツゾラ",
        "filename": "natuzora.mp3",
        "author": "カワサキヤスヒロ",
        "tags": [
            "穏やか",
            "激しい",
            "歌あり",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "ホットミルク",
        "filename": "hotmilk.mp3",
        "author": "カワサキヤスヒロ",
        "tags": [
            "穏やか",
            "歌あり",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": true,
        "name": "Café Au Lait",
        "filename": "cafe_au_lait.mp3",
        "author": "龍崎一",
        "tags": [
            "歌あり",
            "悲しい",
            "前向き",
            "感動"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "少女霊",
        "filename": "shoujorei.mp3",
        "author": "甘茶の音楽工房",
        "tags": [
            "オルゴール",
            "悲しい",
            "ホラー",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Reflect",
        "filename": "reflect.mp3",
        "author": "まんぼう二等兵",
        "tags": [
            "激しい",
            "前向き",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Sagittarius",
        "filename": "sagittarius.mp3",
        "author": "まんぼう二等兵",
        "tags": [
            "激しい",
            "悲しい",
            "前向き",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Raw Essay ",
        "filename": "raw_essay.mp3",
        "author": "Anonyment",
        "tags": [
            "穏やか",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Breath ",
        "filename": "breath.mp3",
        "author": "Anonyment",
        "tags": [
            "穏やか",
            "前向き"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "WELCOMEB4CK",
        "filename": "welcomeb4ck.mp3",
        "author": "ISAo",
        "tags": [
            "激しい",
            "和風",
            "シリアス",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "Siege Buster",
        "filename": "siege_buster.mp3",
        "author": "POLARIS PLUS",
        "tags": [
            "激しい",
            "前向き",
            "シリアス",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "魔の銃弾",
        "filename": "mano_judan.mp3",
        "author": "かずち",
        "tags": [
            "激しい",
            "シリアス",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "タイムベンド",
        "filename": "time_bend.mp3",
        "author": "かずち",
        "tags": [
            "激しい",
            "シリアス",
            "戦闘",
            "緊張感"
        ]
    },
    {
        "isNew": false,
        "volumeAttention": true,
        "isVocal": false,
        "name": "ミッションスタート",
        "filename": "mission_start.mp3",
        "author": "田中芳典",
        "tags": [
            "激しい",
            "シリアス",
            "戦闘",
            "緊張感"
        ]
    },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "全てを創造する者「Dominus Deus」",
//     "filename": "dominus_deus.mp3",
//     "author": "KK",
//     "tags": [
//       "激しい",
//       "シリアス",
//       "戦闘",
//       "緊張感"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "Thug Unruly",
//     "filename": "thug_unruly.mp3",
//     "author": "POLARIS PLUS",
//     "tags": [
//       "激しい",
//       "シリアス",
//       "戦闘"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "The Puzzlewood",
//     "filename": "the_puzzlewood.mp3",
//     "author": "松浦洋介",
//     "tags": [
//       "悲しい",
//       "ホラー",
//       "緊張感"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Rain",
//     "filename": "rain2.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "ピアノ",
//       "悲しい",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Time",
//     "filename": "time.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "前向き"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "間",
//     "filename": "aida.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "悲しい",
//       "前向き",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Green Time",
//     "filename": "green_time.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "前向き"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "Fun Friends",
//     "filename": "fun_friends.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "ピアノ",
//       "前向き"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": true,
//     "name": "Despair and Hope",
//     "filename": "despair_and_hope.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "激しい",
//       "歌あり",
//       "ピアノ",
//       "悲しい",
//       "前向き",
//       "感動",
//       "戦闘",
//       "緊張感"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Peaceful",
//     "filename": "peaceful.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "悲しい",
//       "前向き",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "少しの時間",
//     "filename": "sukoshinozikan.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "悲しい",
//       "前向き",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Future City",
//     "filename": "future_city.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "前向き",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": true,
//     "name": "Paper City",
//     "filename": "paper_city.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "歌あり",
//       "悲しい",
//       "前向き",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Yesterday",
//     "filename": "yesterday.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "前向き"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "Ultra Hero",
//     "filename": "ultra_hero.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "激しい",
//       "前向き",
//       "感動",
//       "戦闘",
//       "緊張感"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "待ち合わせ",
//     "filename": "machiawase.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "悲しい",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "おしるこ",
//     "filename": "oshiruko.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "ピアノ",
//       "前向き",
//       "感動"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "文明開化",
//     "filename": "bunmeikaika.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "激しい",
//       "前向き",
//       "感動",
//       "和風"
//     ]
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "暖かい島",
//     "filename": "atatakaishima.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "前向き"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "違和感",
//     "filename": "iwakan.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "オルゴール",
//       "悲しい",
//       "ホラー"
//     ]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": true,
//     "name": "資本主義",
//     "filename": "shihonsyugi.mp3",
//     "author": "Fukagawa",
//     "tags": [
//       "穏やか",
//       "歌あり",
//       "ピアノ",
//       "悲しい",
//       "前向き",
//       "感動"
//     ]
//   },

//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "satisfaction",
//     "filename": "satisfaction.mp3",
//     "author": "のる",
//     "tags":[ "穏やか", "ピアノ", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Umbrella",
//     "filename": "umbrella.mp3",
//     "author": "Fukagawa",
//     "tags":[ "穏やか", "ピアノ", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Flowers at night",
//     "filename": "flowers_at_night.mp3",
//     "author": "Flehmann",
//     "tags":[ "穏やか", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "風光る並木道",
//     "filename": "fukourunamikimichi.mp3",
//     "author": "ハヤシユウ",
//     "tags":[ "穏やか", "悲しい", "感動", "和風" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "禁足領域",
//     "filename": "kintokuiki.mp3",
//     "author": "のる",
//     "tags":[ "ピアノ", "悲しい", "ホラー", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "来迎",
//     "filename": "raigyo.mp3",
//     "author": "ハシマミ",
//     "tags":[ "穏やか", "悲しい", "ホラー", "和風" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "死者の呼び声",
//     "filename": "shisha_no_koe.mp3",
//     "author": "Make a field Music",
//     "tags":[ "ホラー", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": true,
//     "name": "言花",
//     "filename": "kotohana.mp3",
//     "author": "龍崎一",
//     "tags":[ "歌あり", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": true,
//     "name": "Ronto",
//     "filename": "rontou.mp3",
//     "author": "龍崎一",
//     "tags":[ "穏やか", "歌あり", "悲しい" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "人狼の為の子守唄",
//     "filename": "jinrou_no_tame_no_kodomouta.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "ピアノ", "悲しい" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "露草",
//     "filename": "tsuyukusa.mp3",
//     "author": "MATSU",
//     "tags":[ "穏やか", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "星の破線",
//     "filename": "hoshi_no_hashisen.mp3",
//     "author": "稿屋 隆",
//     "tags":[ "穏やか", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "帰り道",
//     "filename": "kaerimichi.mp3",
//     "author": "Haruichi",
//     "tags":[ "穏やか", "ピアノ", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "飴玉ころころ",
//     "filename": "amedama_korokoro.mp3",
//     "author": "Haruichi",
//     "tags":[ "穏やか", "ピアノ" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "雪の記憶",
//     "filename": "yukino_kioku.mp3",
//     "author": "Haruichi",
//     "tags":[ "穏やか", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "ワスレナグサ",
//     "filename": "wasurenagusa.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "激しい", "ピアノ", "環境音", "悲しい", "前向き", "感動", "戦闘" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "タクティカルエンカウンター",
//     "filename": "tactical_encounter.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "前向き", "戦闘", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Luculia",
//     "filename": "luculia.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "穏やか", "激しい", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "Ride out",
//     "filename": "ride_out.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "激しい", "前向き", "戦闘", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "a beautiful sunset",
//     "filename": "a_beautiful_sunset.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "激しい", "戦闘", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "thinking",
//     "filename": "thinking.mp3",
//     "author": "のる",
//     "tags":[ "穏やか", "ピアノ", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "万緑の芽吹き",
//     "filename": "manryoku_mebuki.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "穏やか", "ピアノ", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "遠い踏切",
//     "filename": "toui_toge.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "穏やか", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "月下美人",
//     "filename": "gekkanbijin.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "穏やか", "激しい", "ピアノ", "悲しい", "前向き", "感動", "戦闘", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "ツユクサの丘",
//     "filename": "tsuyukusa_no_oka.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "穏やか", "激しい", "ピアノ", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "野良猫は宇宙を目指した",
//     "filename": "noraneko_wa_uchuu_ni_mukau.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "しゅわしゅわハニーレモン350ml",
//     "filename": "shuwashuwa_honey_lemon_350ml.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "激しい", "前向き"],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "cassette_tape_dream",
//     "filename": "cassette_tape_dream.mp3",
//     "author": "しゃろう",
//     "tags":[ "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "you_and_me",
//     "filename": "you_and_me.mp3",
//     "author": "しゃろう",
//     "tags":[ "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "極東の羊、テレキャスターと踊る",
//     "filename": "kyokuto_no_hitsuji.mp3",
//     "author": "しゃろう",
//     "tags":[ "激しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "10℃",
//     "filename": "10c.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "激しい", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "ローファイ少女は今日も寝不足",
//     "filename": "lowfi_shoujo_wa_kyou_mo_nemuzu.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "雑踏 街頭02a",
//     "filename": "city_ambi-street02-1.mp3",
//     "author": "OtoLogic",
//     "tags":[ "環境音" ],
//   },
//  {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "雑踏 街頭02b",
//     "filename": "city_ambi-street02-2.mp3",
//     "author": "OtoLogic",
//    "tags":[ "環境音" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "テクスチャー 環境01",
//     "filename": "texture_ambi01-1.mp3",
//     "author": "OtoLogic",
//   "tags":[ "環境音", "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "ロンドンの死",
//     "filename": "london_death.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "オルゴール", "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "殺戮マシン",
//     "filename": "tin_coffee_maker.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "人形遊び",
//     "filename": "doll_play.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "呪われた子",
//     "filename": "cursed_child.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "VEIL オープニング",
//     "filename": "veil.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "確執",
//     "filename": "conflict.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Moment",
//     "filename": "moment.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "穏やか", "激しい", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Moment~オルゴールVer~",
//     "filename": "moment_orgel.mp3",
//     "author": "H/MIX GALLERY",
//     "tags":[ "オルゴール", "悲しい", "前向き", "感動" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "違和感",
//     "filename": "iwakan2.mp3",
//     "author": "H/MIX GALLERY",
//   "tags":[ "ピアノ", "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "深淵に臨む",
//     "filename": "sinnennni_nozomu.mp3",
//     "author": "H/MIX GALLERY",
//   "tags":[ "激しい", "戦闘", "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "白昼夢",
//     "filename": "hakutyuumu.mp3",
//     "author": "H/MIX GALLERY",
//   "tags":[ "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "火竜の洞窟",
//     "filename": "dragon_cave.mp3",
//     "author": "H/MIX GALLERY",
//   "tags":[ "激しい", "戦闘", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "飛翔",
//     "filename": "soaring.mp3",
//     "author": "H/MIX GALLERY",
//   "tags":[ "激しい", "前向き", "戦闘", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "【ボス戦】聖域のデーモン",
//     "filename": "battle-sanctuary.mp3",
//     "author": "ユーフルカ",
//   "tags":[ "激しい", "戦闘", "ホラー", "緊張感" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "【ボス戦】紫桜の妖、千夜の夢の主",
//     "filename": "battle-ayakashi.mp3",
//     "author": "ユーフルカ",
//   "tags":[ "激しい", "前向き", "感動", "戦闘", "緊張感", "和風" ],
//   },
// {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "【ボス戦】終末のリチェルカーレ",
//     "filename": "battle-ricercare.mp3",
//     "author": "ユーフルカ",
//   "tags":[ "激しい", "戦闘", "緊張感" ],
//   },
//
// {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "【ボス戦】覚悟ができたら掛かってきな!",
//     "filename": "battle-resolution.mp3",
//     "author": "ユーフルカ",
//   "tags":[ "激しい", "戦闘", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "【ボス戦】Integral One",
//     "filename": "Integral-one.mp3",
//     "author": "ユーフルカ",
//   "tags":[ "激しい", "戦闘", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "【イベント】記憶のオルゴール",
//     "filename": "childhood.mp3",
//     "author": "ユーフルカ",
//     "tags":[ "オルゴール", "悲しい", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "ある春の日に…(One spring day...)",
//     "filename": "aru_haru_no_hi_ni.mp3",
//     "author": "蒲鉾さちこ",
//     "tags":[ "穏やか", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Horizon Blue",
//     "filename": "horizon_blue.mp3",
//     "author": "Ryo Lion",
//     "tags":[ "穏やか", "ピアノ", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "日々と泉",
//     "filename": "nichigoto_izumi.mp3",
//     "author": "かずち",
//     "tags":[ "穏やか", "ピアノ", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Summer Green",
//     "filename": "summer_green.mp3",
//     "author": "Ryo Lion",
//     "tags":[ "穏やか", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "微睡んで夢の入り口的なBGM",
//     "filename": "mimizande_yume_no.mp3",
//     "author": "鷹尾まさき(タカオマサキ)",
//     "tags":[ "穏やか", "ピアノ", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "枯れ葉",
//     "filename": "kareha.mp3",
//     "author": "ハヤシユウ",
//     "tags":[ "ピアノ", "悲しい", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "花夜行",
//     "filename": "hanayogo.mp3",
//     "author": "のる",
//     "tags":[ "ピアノ", "悲しい", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "日常の裏にある悪意的なBGM",
//     "filename": "nichijou_no_ura_ni_aru.mp3",
//     "author": "鷹尾まさき(タカオマサキ)",
//     "tags":[ "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Morning",
//     "filename": "morning.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "lost",
//     "filename": "lost.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "追憶",
//     "filename": "tsuioku.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "ピアノ", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "Dancer",
//     "filename": "dancer.mp3",
//     "author": "しゃろう",
//     "tags":[ "激しい", "悲しい", "前向き", "感動", "戦闘" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "全てが終わる夜に",
//     "filename": "subete_ga_owaru_yoruni.mp3",
//     "author": "しゃろう",
//     "tags":[ "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "神隠しの真相",
//     "filename": "kamikakushi_no_shinso.mp3",
//     "author": "しゃろう",
//     "tags":[ "ピアノ", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "ステラと塔の物語",
//     "filename": "stella_to_tou_no_monogatari.mp3",
//     "author": "しゃろう",
//     "tags":[ "激しい", "悲しい", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "週末京都現実逃避",
//     "filename": "shumatsu_kyoto_genjitsutouhi.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "前向き", "感動" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "アトリエと電脳世界",
//     "filename": "atorie_to_dennyou_sekai.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "カエルの勇者",
//     "filename": "kaeru_no_yuusha.mp3",
//     "author": "しゃろう",
//     "tags":[ "穏やか", "前向き" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": false,
//     "name": "煉獄道",
//     "filename": "rengokudou.mp3",
//     "author": "こおろぎ",
//     "tags":[ "激しい", "戦闘", "ホラー", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "呪いに侵食され歪んでゆく空間",
//     "filename": "noroi_ni_shinsetsu.mp3",
//     "author": "KK",
//     "tags": ["ホラー"]
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "春の箱舟",
//     "filename": "haru_no_hakobasho.mp3",
//     "author": "まんぼう二等兵",
//     "tags":[ "穏やか", "ピアノ", "前向き", "和風" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "流るる時",
//     "filename": "nagaruru_toki.mp3",
//     "author": "siroimu",
//     "tags":[ "穏やか", "悲しい", "前向き", "感動", "和風" ],
//   },
//
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "おちゃたたみ",
//     "filename": "ochatatami.mp3",
//     "author": "んぃん",
//     "tags":[ "穏やか", "前向き", "和風" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": false,
//     "isVocal": false,
//     "name": "冷たい雨",
//     "filename": "tsumetai_ame.mp3",
//     "author": "Notzan ACT",
//     "tags":[ "悲しい", "ホラー", "緊張感" ],
//   },
//   {
//     "isNew": false,
//     "volumeAttention": true,
//     "isVocal": true,
//     "name": "寝不足",
//     "filename": "nebusoku.mp3",
//     "author": "sentive",
//     "tags":[ "歌あり", "前向き", "感動" ],
//   }
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Notion",
        "filename": "notion.mp3",
        "author": "RYU ITO",
        "tags": ["穏やか", "前向き"],
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Night Walking",
        "filename": "night_walking.mp3",
        "author": "RYU ITO",
        "tags": ["穏やか", "前向き"],
    },
    // {
    //   "isNew": false,
    //   "volumeAttention": false,
    //   "isVocal": false,
    //   "name": "Woozy",
    //   "filename": "woozy.mp3",
    //   "author": "RYU ITO",
    //   "tags": ["穏やか", "前向き"],
    // },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Yawn",
        "filename": "yawn.mp3",
        "author": "RYU ITO",
        "tags": ["穏やか", "前向き"],
    },
    //  {
    //    "isNew": false,
    //    "volumeAttention": false,
    //    "isVocal": false,
    //    "name": "Cozy",
    //    "filename": "cozy.mp3",
    //    "author": "RYU ITO",
    //    "tags": ["穏やか", "前向き"],
    //  },
    //  {
    //    "isNew": false,
    //    "volumeAttention": false,
    //    "isVocal": false,
    //    "name": "Low",
    //    "filename": "low.mp3",
    //    "author": "RYU ITO",
    //    "tags": ["穏やか"],
    //  },
    // {
    //    "isNew": false,
    //    "volumeAttention": false,
    //    "isVocal": false,
    //    "name": "Toy",
    //    "filename": "toy.mp3",
    //    "author": "RYU ITO",
    //    "tags": ["穏やか", "前向き"],
    //  },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Reflection",
        "filename": "reflection.mp3",
        "author": "RYU ITO",
        "tags": ["穏やか", "前向き", "環境音"],
    },
    {
        "isNew": false,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Bloom",
        "filename": "bloom.mp3",
        "author": "RYU ITO",
        "tags": ["穏やか", "前向き"],
    },
    {
        "isNew": true,
        "volumeAttention": false,
        "isVocal": false,
        "name": "Daydream",
        "filename": "daydream.mp3",
        "author": "shimtone",
        "tags": ["穏やか", "ピアノ", "悲しい"],
    },
    {
        "isNew": true,
        "volumeAttention": true,
        "isVocal": true,
        "name": "Yellow",
        "filename": "yellow.mp3",
        "author": "龍崎一",
        "tags": ["歌あり", "前向き"],
    },
    // {
    //     "isNew": true,
    //     "volumeAttention": true,
    //     "isVocal": true,
    //     "name": "暗闇と少女",
    //     "filename": "yami_yami_to_shoujo.mp3",
    //     "author": "ゆうり",
    //     "tags": ["歌あり", "オルゴール", "悲しい", "ホラー"],
    // },
    {
        "isNew": true,
        "volumeAttention": true,
        "isVocal": true,
        "name": "No Working New Day",
        "filename": "no_working_new_day.mp3",
        "author": "龍崎一",
        "tags": ["穏やか", "歌あり", "前向き", "感動"],
    },
    {
        "isNew": true,
        "volumeAttention": true,
        "isVocal": true,
        "name": "夜の独り言",
        "filename": "yoru_no_hitorigoto.mp3",
        "author": "Otakara BGM",
        "tags": ["穏やか", "歌あり", "前向き", "感動"],
    },
    // {
    //     "isNew": true,
    //     "volumeAttention": true,
    //     "isVocal": true,
    //     "name": "星の響き",
    //     "filename": "hoshi_no_hibiki.mp3",
    //     "author": "Otakara BGM",
    //     "tags": ["歌あり", "悲しい", "前向き"],
    // },
    {
        "isNew": true,
        "volumeAttention": true,
        "isVocal": true,
        "name": "夜に溶けた",
        "filename": "yoru_ni_toketa.mp3",
        "author": "Otakara BGM",
        "tags": ["穏やか", "歌あり", "悲しい"],
    },


]