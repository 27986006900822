<template>
  <span>
    <label for="file1" class="visibleButton">
      <font-awesome-icon icon="upload"/>
      .txtインポート
    </label>
    <input type="file" ref="file" id="file1" @change="loadFile">
  </span>
</template>

<script>
export default {
  name: "ImportText",
  methods: {
    loadFile($event) {
      let file = $event.target.files[0];

      if ("text/plain" != file.type) {
        alert('テキストファイルではありません。');
        return false;
      }

      let reader = new FileReader();
      reader.onload = () => {
        if (true == confirm('現在のテキストを上書きします。よろしいですか？\n※文字コードUTF-8でない場合、文字化けします。')) {
          this.$emit('input', reader.result);
        }
        $event.target.value = '';
      }
      reader.readAsText(file);
    }
  }

}
</script>

<style scoped>

.visibleButton {
  background: rgba(20, 20, 20, .7);
  color: #fff;
  border: none;
  margin-right: 10px;
  transition: opacity .5s;
  border-radius: 4px;
  font-size: 83%;
  padding: 4px 17px;
  letter-spacing: .1em;
  display: inline-block;
  cursor: pointer;
}

.visibleButton:hover {
  opacity: .5;
}


[type=file] {
  display: none;
}

</style>