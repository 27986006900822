<template>
  <div class="cover">

    <div class="buttonClose" @click="$emit('close')">
      <a href="#" @click.prevent>
        <font-awesome-icon icon="times-circle"/>
      </a>
    </div>
    <h3>
      track list
    </h3>

    <div class="playingTrackTitle">
      <strong class="trackName">
        <!--        <font-awesome-icon icon="play"/>-->
        ♪「{{ playingTrack.name }}」
      </strong>
      <span class="author">/{{ playingTrack.author }}</span>
    </div>

    <div class="trackPlayMode">
      <ul>
        <li>
          <input type="radio" id="play_mode_loop" name="play_mode" v-model="innerTrackPlayMode" value="loop" @change="changeTrackPlayMode">
          <label for="play_mode_loop">ループ再生</label>
        </li>
        <li>
          <input type="radio" id="play_mode_random" name="play_mode" v-model="innerTrackPlayMode" value="random" @change="changeTrackPlayMode">
          <label for="play_mode_random">シャッフル再生</label>
        </li>
      </ul>
    </div>

    <ul class="tagList">
      <li :class="{'selectedTag':isSelectedTagAll() == true}">
        <a href="#" @click.prevent="clearTag" class="fwBold">
          すべて
        </a>
      </li>
      <li v-for="(tag, index) in tags" :key="index" :class="{'selectedTag':tag.isSelected == true}">
        <a href="#" @click.prevent="selectTag(index)">
          {{ tag.name }}
        </a>
      </li>
    </ul>

    <ul class="trackList">

      <transition-group name="fadeAnime">
        <li v-for="(track, index) in getTracks()" :key="index" :class="{'playingTrack':track.filename == playingTrack.filename}">

          <span v-if="track.filename == playingTrack.filename" class="trackIcon">
            <font-awesome-icon icon="play"/>
          </span>
          <span v-else class="trackIcon">
            <font-awesome-icon icon="minus"/>
          </span>

          <a :href="track.filename" @click.prevent="trackChange(track)" :class="{'isNew':true == track.isNew}">
            <span>
              <span v-if="true == track.volumeAttention" class="volumeAttention">
                <font-awesome-icon icon="exclamation-triangle"/>
                音量注意
              </span>
              <template v-if="true == track.isVocal">
                （Vo）
              </template>
              <!--            <font-awesome-icon icon="microphone" />-->
              {{ track.name }}
            </span>
            <span class="trackAuthor">
              /{{ track.author }}
            </span>
          </a>

        </li>
      </transition-group>
    </ul>

    <ul class="materialList">
      <h4>track by</h4>
      <li><a href="https://dova-s.jp/" target="_blank">DOVA-SYNDROME</a></li>
      <li><a href="https://soundeffect-lab.info/" target="_blank">効果音ラボ</a></li>
      <li><a href="https://sentive.net/" target="_blank">sentive</a></li>
      <li><a href="https://shw.in/" target="_blank">SHW</a></li>
      <li><a href="https://otologic.jp/" target="_blank">OtoLogic</a></li>
      <li><a href="https://youfulca.com/" target="_blank">ユーフルカ</a></li>
      <li><a href="https://ryu110.com/" target="_blank">RYU ITO</a></li>
      <li><a href="https://www.youtube.com/@otakara_BGM" target="_blank">Otakara BGM</a></li>
    </ul>

  </div>
</template>

<script>

import tracks from "@/data/tracks.js"

export default {
  name: "TrackSelect",
  props: {
    playingTrack: {
      type: Object
    },
    trackPlayMode: {
      type: String,
      default: "loop"
    }
  },
  data() {
    return {
      tags: [
        {name: '穏やか', isSelected: false},
        {name: '激しい', isSelected: false},
        {name: '歌あり', isSelected: false},
        {name: 'ピアノ', isSelected: false},
        {name: 'オルゴール', isSelected: false},
        {name: '環境音', isSelected: false},
        {name: '悲しい', isSelected: false},
        {name: '前向き', isSelected: false},
        {name: '感動', isSelected: false},
        {name: '戦闘', isSelected: false},
        {name: 'ホラー', isSelected: false},
        {name: '緊張感', isSelected: false},
        {name: '和風', isSelected: false}
      ],
      tracks: tracks,
      innerTrackPlayMode: null
    }
  },
  mounted() {
    this.innerTrackPlayMode = this.trackPlayMode;
  },
  methods: {
    trackChange(track) {
      this.$emit('input', track);
    },
    changeTrackPlayMode(event) {
      this.$emit('change-track-play-mode', event.target.value);
    },
    getTracks() {

      if (true == this.isSelectedTagAll()) {
        return this.tracks;
      }

      // 選択中タグのみに絞り込む
      let selectedTags = this.tags.filter(tag => {
        return true == tag.isSelected;
      });

      // trackをフィルタリング
      return this.tracks.filter(track => {
        let trackHasTag = true;
        selectedTags.forEach(tag => {
          if (false == track.tags.includes(tag.name)) { // includesはindexOfの新型
            trackHasTag = false;
          }
        });
        return trackHasTag;
      });

    },
    selectTag(index) {

      // 全タグのクリアを挟む。複数選択可能にするなら外す。
      this.tags.map(tag => {
        return tag.isSelected = false;
      });

      this.tags[index].isSelected = true;

      // return this.toggleTag(index);
    },
    // toggleTag(index) {
    //   this.tags[index].isSelected = (!this.tags[index].isSelected);
    // },
    clearTag() {
      this.tags.map((tag) => {
        return tag.isSelected = false;
      });
    },
    /**
     * タグ選択「すべて」か。
     * @returns {boolean}
     */
    isSelectedTagAll() {
      let flag = true;
      this.tags.forEach((tag) => {
        if (true == tag.isSelected) {
          flag = false;
        }
      });

      return flag;
    }
  }
}
</script>

<style scoped>

.cover {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #ddd;
  letter-spacing: .2em;
  /*font-size: 200%;*/
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 40px 60px;
  /*margin: 10px;*/
  overflow-y: auto;
}


.cover::-webkit-scrollbar {
  width: 0;
}


a {
  color: #ddd;
  text-decoration: none;
}

a:hover {
  color: #aaa;
}

.playingTrackTitle {
  padding-left: 20px;
}

.playingTrackTitle .trackName{
  font-size: 120%;
}

.playingTrackTitle .author{
}

.trackPlayMode {
  text-align: right;
  /*margin-top: 10px;*/
  /*margin-bottom: 20px;*/
}

.trackPlayMode ul {
  display: inline-block;
  /*margin-left: 20px;*/
  padding-left: 10px;
}

.trackPlayMode li {
  display: inline-block;
  list-style-type: none;
  margin-right: 30px;
}

@media screen and (min-width: 900px) {
  .trackList li {
    width: 50%;
    display: inline-block;
  }
}

.trackList li a {
  padding: 2px;
}

.trackList li a span {
  display: inline-block;
}

.trackList li a:hover span {
  transform: rotateX(360deg);
  transition: transform 2.5s;
}

.trackList li a.isNew:after {
  content: "・";
  color: #ca884c;
  font-size: 16px;
  vertical-align: top;
  margin-left: 0px;
  animation: 1s linear 0s infinite alternate isNewBlinking;
  /*opacity: 0;*/
}

@keyframes isNewBlinking {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.buttonClose {
  margin-left: auto;
  font-size: 160%;
  display: block;
  width: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
}

.trackList {
  line-height: 1.8;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

.trackIcon {
  display: inline-block;
  margin-right: 10px;
  font-size: 70%;
}

.playingTrack, .playingTrack a {
  color: #aaa;
}

.volumeAttention {
  font-size: 60%;
  background: #ccc;
  color: #000;
  padding: 0 3px;
}

.trackAuthor {
  font-size: 70%;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0;
}

.tagList li {
  list-style: none;
  margin-right: 5px;
  margin-bottom: 10px;
}

.tagList li a {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 1px 15px;
  font-size: 80%;
  white-space: nowrap;
}

.tagList li.selectedTag a {
  background: #ddd;
  color: #333;
}

.fwBold {
  font-weight: bold;
}


.fadeAnime-enter-active, .fadeAnime-leave-active {
  transition: all .3s;
  transform: translateX(0);
}

.fadeAnime-enter, .fadeAnime-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(4px);
}


.materialList {
  width: 200px;
  margin-left: auto;
  font-size: 90%;
  color: #aaa;
  letter-spacing: .1em;
}

.materialList h4 {
  margin-bottom: 10px;
}

.materialList li {
  list-style-position: inside;
  font-size: 85%;
  padding-left: 10px;
}

.materialList a {
  color: #aaa;
}


</style>