<template>
  <div class="cover">

    <div class="buttonClose" @click="$emit('close')">
      <a href="#" @click.prevent>
        <font-awesome-icon icon="times-circle"/>
      </a>
    </div>
    <h3 style="margin-bottom: 50px;">
      option
    </h3>

    <section class="optionCell">
      <label>
        <input type="radio" name="horizontalMode" v-model="innerHorizontalMode" :value="false" @change="$emit('input-horizontal-mode', innerHorizontalMode)"> 縦書きモード
      </label>
      <label>
        <input type="radio" name="horizontalMode" v-model="innerHorizontalMode" :value="true" @change="$emit('input-horizontal-mode', innerHorizontalMode)"> 横書きモード
      </label>
    </section>

    <section class="optionCell">
      <label>
        文字サイズ
        <input type="number"
               name="fontSize"
               v-model="innerFontSize"
               @change="$emit('input-font-size', parseFloat(innerFontSize))"
               min="10"
               max="25"
        >
      </label>
    </section>

    <section class="optionCell">
      <label>
        テキスト枠の透明度
        <input type="number"
               name="baseOpacity"
               v-model="innerBaseOpacity"
               @change="$emit('input-base-opacity', parseFloat(innerBaseOpacity))"
               min="0"
               max="100"
        > ％
      </label>
    </section>

    <section class="optionCell">
      <a href="#" class="ghostButton saveOption" target="_blank" @click.prevent="saveOption" >
        設定を保存する
      </a>

      <a href="#" class="ghostButton loadOption" target="_blank" @click.prevent="loadOption" v-if="true == hasSavedOption()">
        設定を読み込む
      </a>
      <span class="ghostButton saveOption disabled" v-if="false == hasSavedOption()">
        設定を読み込む
      </span>
    </section>

    <section class="optionCell textRight">

      <a href="#" @click.prevent="resetInitialBodyCount" class="ghostButton shareOption" target="_blank">
        文字数リセット
      </a>

      <a :href="shareEditorUrl" class="ghostButton shareOption" target="_blank">
        <img src="images/tw.png" alt="" width="16" height="16" style="vertical-align: top;">
        設定共有
      </a>
    </section>

  </div>
</template>

<script>
export default {
  name: "TrackSelect",
  props: {
    horizontalMode: {
      type: Boolean
    },
    fontSize: {
      type: Number
    },
    baseOpacity: {
      type: Number
    },
    shareEditorUrl: {
      type: String
    }
  },
  data() {
    return {
      innerHorizontalMode: null,
      innerFontSize: null,
      innerBaseOpacity: null,
      storageKeyOption: 'iroha_option'
    }
  },
  mounted() {
    this.innerHorizontalMode = this.horizontalMode;
    this.innerFontSize = this.fontSize;
    this.innerBaseOpacity = this.baseOpacity;
  },
  methods: {
    saveOption() {

      let optionData = {
        playingTrack: this.$parent.$data.playingTrack,
        selectingImage: this.$parent.$data.selectingImage,
        horizontalMode: this.$parent.$data.horizontalMode,
        fontSize: this.$parent.$data.fontSize,
        baseOpacity: this.$parent.$data.baseOpacity,
      };

      localStorage.setItem(this.storageKeyOption, JSON.stringify(optionData))
      // console.log(optionData);

      alert('設定を保存しました。');

      this.$forceUpdate();
    },
    loadOption() {

      if (false == this.hasSavedOption()) {
        return;
      }

      if (!confirm('設定を読み込みますか？')) {
        return;
      }

      this.$parent.$data.horizontalMode = true;

      let savedOption = localStorage.getItem(this.storageKeyOption);

      savedOption = JSON.parse(savedOption);

      this.$parent.trackChange(savedOption.playingTrack);
      this.$parent.imageChange(savedOption.selectingImage);
      this.$parent.$data.horizontalMode = savedOption.horizontalMode;
      if (undefined !== savedOption.fontSize) {
        this.$parent.$data.fontSize = savedOption.fontSize;
      }
      if (undefined !== savedOption.baseOpacity){
        this.$parent.$data.baseOpacity = savedOption.baseOpacity;
      }
    },
    hasSavedOption() {
      let savedOption = localStorage.getItem(this.storageKeyOption);

      if (savedOption && JSON.parse(savedOption) instanceof Object) {
        return true;
      } else {
        return false;
      }
    },

    resetInitialBodyCount()
    {
      if (!confirm('進捗文字数をリセットしますか？')) {
        return;
      }

      this.$emit('reset-initial-body-count');
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

.cover {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #ddd;
  letter-spacing: .2em;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 40px 60px;
  overflow-y: auto;
}

.cover::-webkit-scrollbar {
  width: 0;
}

a {
  color: #ddd;
  text-decoration: none;
}

a:hover {
  color: #aaa;
}


.buttonClose {
  margin-left: auto;
  font-size: 160%;
  display: block;
  width: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
}

label {
  margin-right: 15px;
}

.optionCell {
  margin-bottom: 30px;
}

[name="fontSize"], [name="baseOpacity"] {
  font-size: 16px;
  background: rgba(100, 100, 100, .2);
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 3px;
}

.ghostButton {
  display: inline-block;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  font-size: 80%;
  color: #ccc;
  border: 2px solid #ccc;
  background: none;
  padding: 3px 16px;
  border-radius: 6px;
  margin-right: 10px;
  margin-left: auto;
  margin-top: 40px;
}

.ghostButton.disabled{
  opacity: .5;
}

.shareOption {
  /*width: 105px;*/
  transition: opacity .5s;
 }


.textRight {
  text-align:right;
}

</style>