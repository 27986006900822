<template>
  <div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <h3 style="font-size: 30px;font-weight: normal;">
      試作型「いろはエディタ」
    </h3>
    <p>
      ［クリックしてはじめてください］
    </p>

    <p style="color: #d65555;font-size: 120%; padding-top: 30px;">
      ※BGMが鳴ります。ご注意ください。
    </p>

    <div style="max-width: 500px;margin-left: auto; padding-top: 20px; text-align:left; font-size: 80%;color: #777;">
      <h3>更新履歴</h3>
      <p>20240830 音楽素材（ボーカル系）を追加しました。</p>
<!--      <p>20240516 背景画像（小物系、他）を追加しました。</p>-->
<!--      <p>20240220 音楽素材（チル系）を追加しました。</p>-->
<!--      <p>20231214 背景画像（単色系）を追加しました。テキスト枠の透明度設定機能を追加しました。</p>-->
<!--      <p>20230710 音楽素材（シリアス系）を追加しました。</p>-->
<!--      <p>20220821 「設定を変更する」に「文字数リセット」を追加しました。</p>-->
<!--      <p>20220821 スクロールバーの色を変更しました。</p>-->
<!--      <p>20220522 音楽のシャッフル再生モードを追加しました。</p>-->
<!--      <p>20220430 音楽を追加しました。</p>-->
<!--      <p>20220226 音楽を追加しました。</p>-->
<!--      <p>20220219 設定保存機能を追加しました。</p>-->
<!--      <p>20212118 音楽素材（ピアノ曲系）を追加しました。</p>-->
<!--      <p>20211031 音楽の絞り込み機能を追加しました。</p>-->
<!--      <p>20211028 背景画像を追加しました。</p>-->
<!--      <p>20211016 半角英数字が縦向きになるよう修正しました。</p>-->
<!--      <p>20211013 音楽素材（主に戦闘曲系2）を追加しました。</p>-->
<!--      <p>20211005 オプションに文字サイズ変更機能を追加しました。</p>-->
<!--      <p>20210923 テキストインポート時に文字数カウントをリセットするよう修正しました。</p>-->
<!--      <p>20210923 音楽素材（主に戦闘曲系）を追加しました。</p>-->
<!--      <p>20210918 背景画像を追加しました。</p>-->
<!--      <p>20210813 音楽素材（ピアノ系）を追加しました。</p>-->
<!--      <p>20210813 音楽素材（オルゴール系）を追加しました。</p>-->
<!--      <p>20210717 音楽素材（和風曲系）を追加しました。</p>-->
<!--      <p>20210629 進捗報告機能を追加しました。</p>-->
<!--      <p>20210628 設定共有機能を追加しました。</p>-->
<!--      <p>20210627 音楽素材を追加しました。</p>-->
<!--      <p>20210626 音楽素材を追加しました。</p>-->
<!--      <p>20210624 テキストファイルインポートを追加しました。</p>-->
<!--      <p>20210623 「三点ボタン」「ダッシュボタン」を追加しました。</p>-->
<!--      <p>20210622 音楽に環境音系を追加しました。</p>-->
<!--      <p>20210622 画像選択機能、新しい画像を追加しました。</p>-->
<!--      <p>20210621 新しい音楽を追加しました。</p>-->
<!--      <p>20210620 文字数表示、横書きモードを追加しました。</p>-->
<!--      <p>20210619 背景画像がランダム表示になりました。</p>-->
<!--      <p>20210619 音楽選択機能、新しい音楽を追加しました。</p>-->
<!--      <p>20210619 ルビボタン追加しました。</p>-->
<!--      <p>20210612 txtダウロード追加しました。</p>-->
<!--      <p>20210610 「進む」「戻る」ボタン追加しました。</p>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstCover"
}
</script>

<style scoped>

</style>