<template>
  <div>

    <p class="text-center" style="padding-top: 0; padding-bottom: 20px; font-size: 85%;" v-if="true == isVisibleNotice">
      <a href="https://notes.underxheaven.com/help/shien" target="_blank" style="color: #fff;">
        小説執筆ツール「notes 」の支援特典で「いろはエディタ in notes」が利用できるようになりました。
      </a>
      <a href="#" @click.prevent="isVisibleNotice = false" style="color: #fff;">[閉じる]</a>
    </p>

    <transition name="coverAnime">
      <TrackSelect :playing-track="playingTrack" :track-play-mode="trackPlayMode" @input="trackChange" @change-track-play-mode="changeTrackPlayMode" @close="displayTrackSelect=false" v-show="true == displayTrackSelect"></TrackSelect>
    </transition>
    <transition name="coverAnime">
      <ImageSelect :selecting-image="selectingImage" @input="imageChange" @close="displayImageSelect=false" v-show="true == displayImageSelect"></ImageSelect>
    </transition>
    <transition name="coverAnime">
      <Option :horizontal-mode="horizontalMode" :font-size="fontSize" :base-opacity="baseOpacity" @close="displayOption=false" v-if="true == displayOption" @input-horizontal-mode="horizontalMode = $event" @input-font-size="fontSize = $event"  @input-base-opacity="baseOpacity = $event" :share-editor-url="getShareEditorUrl()"
       @reset-initial-body-count="resetInitialBodyCount"></Option>
    </transition>

    <div id="firstCover" ref="firstCover" @click="firstCoverClose">

      <FirstCover></FirstCover>

    </div>

    <div class="container">
      <div class="text-right topButtons">
        <audio :src="'tracks/'+ playingTrack.filename" controls id="music" ref="music" controlslist="nodownload" @ended="trackEnded"></audio>
        <button type="button" class="buttonTrackChange" @click.prevent="displayTrackSelect = true">
          音楽を選択する
        </button>
        <button type="button" class="buttonImageChange" @click.prevent="displayImageSelect = true">
          画像を選択する
        </button>

        <button type="button" class="buttonOption" @click.prevent="displayOption = true">
<!--          <font-awesome-icon icon="cog"/>-->
          設定を変更する
        </button>

<!--        <a :href="getShareEditorUrl()"-->
<!--           target="_blank"-->
<!--        >-->
<!--          <button class="buttonShareEditorUrl">-->
<!--            <img src="images/tw.png" alt="" width="20" height="20" style="vertical-align: top;">-->
<!--            設定共有-->
<!--          </button>-->
<!--        </a>-->

      </div>

      <div class="text-center">
        <div id="body"
             autofocus
             contenteditable="plaintext-only"
             spellcheck="false"
             ref="body"
             @keydown="bodyKeyDown"
             @keyup="bodyKeyUp"
             @input="bodyInput"
             :class="{'opacity0': false == start,'vertical':false == horizontalMode, 'horizontal':true == horizontalMode}"
             :style="{fontSize:fontSize + 'px', background: 'rgba(0, 0, 0, ' + (0.01 * baseOpacity) + ')'}"
        >
          |吾輩《わがはい》は猫である。名前はまだ無い。<br>
          どこで生れたかとんと|見当《けんとう》がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪どうあくな種族であったそうだ。この書生というのは時々我々を捕つかまえて煮にて食うという話である。しかしその当時は何という考もなかったから別段恐しいとも思わなかった。
          ただ彼の掌てのひらに載せられてスーと持ち上げられた時何だかフワフワした感じがあったばかりである。
        </div>
      </div>


      <div class="d-flex">

        <div class="underButtons">
          <button @click.prevent="historyUndo" title="文章を戻します" class="buttonUndo">
            <font-awesome-icon icon="undo"/>
          </button>
          <button @click.prevent="historyRedo" title="文章を進めます" class="buttonRedo">
            <font-awesome-icon icon="redo"/>
          </button>
          <button @click.prevent="insertRuby" title="" class="buttonRuby textEditButtons">
            《》ルビ
          </button>
          <button @click.prevent="insertDot" title="" class="buttonDot textEditButtons">
            …三点
          </button>
          <button @click.prevent="insertDash" title="" class="buttonDash textEditButtons">
            ―ダッシュ
          </button>
        </div>

        <div class="timer text-right">
          <small class="minutes">
            執筆開始から<span id="minutes">{{ minutes }}</span>分が経過しました。| {{ bodyCount }}文字
            <span style="font-size: 80%;">
              (
              <template v-if="0 < (this.bodyCount - this.initialBodyCount)">+</template>
              {{ this.bodyCount - this.initialBodyCount }}
              )
            </span>
            <img src="images/tw.png" alt="" width="16" height="16" style="vertical-align: middle; margin-top: -3px;">
          </small>
          <small class="sintyoku">
            <a :href="getShareSintyokuUrl()" target="_blank">
              {{ minutes }}分間で{{ this.bodyCount - this.initialBodyCount }}文字執筆しました。
              <img src="images/tw.png" alt="" width="16" height="16" style="vertical-align: middle; margin-top: -3px;">
            </a>
          </small>
        </div>
      </div>

      <div class="text-center">
        <button @click.prevent="downloadTxt" title="ダウンロードする" class="buttonDownload">
          <font-awesome-icon icon="download"/>
          <span>
            .txtダウンロード
          </span>
        </button>


        <ImportText @input="loadFile"></ImportText>

        <!--        <a :href="getMailToParam()" class="buttonMailTo">-->
        <!--          <font-awesome-icon icon="envelope"/>-->
        <!--          メールに書き出す-->
        <!--        </a>-->

      </div>
    </div>


  </div>
</template>

<script>

import FirstCover  from "@/components/FirstCover"
import TrackSelect from "@/components/TrackSelect";
import ImageSelect from "@/components/ImageSelect";
import Option      from "@/components/Option";
import ImportText  from "@/components/ImportText";
import querystring from 'querystring';

export default {
  name: 'App',
  components: {
    FirstCover,
    TrackSelect,
    ImageSelect,
    Option,
    ImportText
  },
  data() {
    return {
      start: false,
      storageKeyBody: 'iroha',
      playingTrack: {},
      trackPlayMode: 'loop',
      selectingImage: {},
      histories: [],
      historyKey: 0,
      displayTrackSelect: false,
      displayImageSelect: false,
      displayOption: false,
      horizontalMode: false,
      fontSize: 18,
      baseOpacity: 60,
      bodyCount: 0,
      initialBodyCount: null,
      minutes: 0,
      isVisibleNotice: true,
    }
  },
  mounted() {

    // urlクエリパラメータ
    let queryStrings = querystring.parse(location.search.replace('?', ''));


    // 背景画像設定
    const images = ImageSelect.data().images;
    // console.log(queryStrings.image && images[queryStrings.image]);
    if (queryStrings.image && images[queryStrings.image]) {
      const image = images[queryStrings.image];
      this.imageChange(image)
    } else {
      const imageKey = Math.floor(Math.random() * (images.length));
      const image = images[imageKey];
      this.imageChange(image)
    }

    // 音楽設定
    let tracks = TrackSelect.data().tracks;
    if (queryStrings.track && tracks[queryStrings.track]) {
      this.playingTrack = tracks[queryStrings.track];
    } else {
      this.playingTrack = tracks[0];
    }

    // 縦横設定
    if (queryStrings.horizontalMode) {
      this.horizontalMode = ('true' === queryStrings.horizontalMode);
    }

    // 文字サイズ設定
    if (queryStrings.fontSize) {
      this.fontSize = queryStrings.fontSize;
    }

    // 背景透明度設定
    if (queryStrings.baseOpacity) {
      this.baseOpacity = queryStrings.baseOpacity;
    }

    var bodyElement = document.querySelector('#body');

    var storageText = localStorage.getItem(this.storageKeyBody);

    if (storageText && storageText.trim()) {
      bodyElement.innerText = storageText;
    }

    this.updateBodyCount();
    // 最初の文字数を記録
    this.initialBodyCount = this.bodyCount;

    setInterval(() => {
      this.minutes++;
    }, 1000 * 60);

  },
  methods: {
    firstCoverClose() {

      this.$refs.firstCover.style.bottom = '2000px'

      // this.$nextTick(() => {
      this.$refs.music.play();
      // });

      this.start = true;
      // this.$refs.body.classList.remove('opacity0');
    },
    bodyKeyDown() {
      this.$refs.body.style.marginRight = '1px';
      setTimeout(() => {
        this.$refs.body.style.marginRight = '0';
      }, 50);
    },
    bodyKeyUp() {
      this.saveLocalStorage();
    },
    bodyInput() {
      this.recordHistory();
      this.updateBodyCount();
    },
    saveLocalStorage() {
      localStorage.setItem(this.storageKeyBody, this.$refs.body.innerText);
    },
    trackChange(track) {
      this.playingTrack = track;

      // 音量大きいので調整 いずれ全ファイルノーマライズする
      if (true == track.volumeAttention && 0.4 < this.$refs.music.volume) {
        this.$refs.music.volume = 0.4
      }

      this.$nextTick(() => {
        this.$refs.music.play();
      });

    },
    trackEnded(){
      if (this.trackPlayMode == 'random') {
        // ランダム選択
        const tracks = TrackSelect.data().tracks;
        const trackKey = Math.floor(Math.random() * (tracks.length));
        const track = tracks[trackKey];
        this.trackChange(track)
        // trackChangeの中で再生実行しているのでplay()は不要
      } else {
        this.$nextTick(()=> {
          this.$refs.music.play();
        });
      }
    },
    changeTrackPlayMode(event){
      console.log(event);
      this.trackPlayMode = event;
    },
    imageChange(image) {
      this.selectingImage = image;
      // document.body.classList.add(image.class);
      document.body.style.backgroundImage = "url(images/" + image.filename + ")";
    },
    recordHistory() {
      let body = this.$refs.body.innerText;

      // 先頭が未来
      this.histories.unshift(body);

      // undo後に入力した時、キー以前を削除
      for (let deleteKey = this.historyKey; 0 < deleteKey; deleteKey--) {
        this.histories.splice(deleteKey, 1);
      }
      this.historyKey = 0;

      // 限界数以上の履歴を削除
      let historyLimit = 100;
      if (historyLimit < this.histories.length) {
        for (let deleteKey = (historyLimit + 1); deleteKey <= this.histories.length; deleteKey++) {
          this.histories.splice(deleteKey, 1);
        }
      }

    },
    historyUndo() {
      if (this.histories[this.historyKey + 1]) {
        this.historyKey++;
        this.$refs.body.innerText = this.histories[this.historyKey];
      }
      this.saveLocalStorage();
    },
    historyRedo() {
      if (this.histories[this.historyKey - 1]) {
        this.historyKey--;
        this.$refs.body.innerText = this.histories[this.historyKey];
      }
      this.saveLocalStorage();
    },
    downloadAsTextFile(fileName, content) {
      const BLOB = new Blob([content], {'type': 'text/plain;charset=utf-8'});
      const CAN_USE_SAVE_BLOB = window.navigator.msSaveBlob !== undefined;

      if (CAN_USE_SAVE_BLOB) {
        window.navigator.msSaveBlob(BLOB, fileName);
        return;
      }

      const TEMP_ANCHOR = document.createElement('a');
      TEMP_ANCHOR.href = URL.createObjectURL(BLOB);
      TEMP_ANCHOR.setAttribute('download', fileName);

      TEMP_ANCHOR.dispatchEvent(new MouseEvent('click'));
    },
    // テキストダウンロード
    downloadTxt() {
      let filename = 'iroha_' + this.$dayjs().format('YYYYMMDD-HHmm') + '.txt';
      let body = this.$refs.body.innerText;
      this.downloadAsTextFile(filename, body);
    },

    // ルビタグ挿入
    insertRuby() {

      // #bodyのみ
      if ('body' != document.getSelection().focusNode.parentElement.id) {
        return;
      }
      let focusOffset = document.getSelection().focusOffset;
      let node = document.getSelection().anchorNode;

      let textArray = node.data.split('');
      textArray.splice(focusOffset, 0, '|《》');
      node.data = textArray.join('');

      this.$refs.body.focus();

      let range = document.getSelection().getRangeAt(0)
      range.setStart(document.getSelection().anchorNode, focusOffset + 2)
    },
    // 三点挿入
    insertDot() {

      // #bodyのみ
      if ('body' != document.getSelection().focusNode.parentElement.id) {
        return;
      }
      let focusOffset = document.getSelection().focusOffset;
      let node = document.getSelection().anchorNode;

      let textArray = node.data.split('');
      textArray.splice(focusOffset, 0, '……');
      node.data = textArray.join('');

      this.$refs.body.focus();

      let range = document.getSelection().getRangeAt(0)
      range.setStart(document.getSelection().anchorNode, focusOffset + 2)
    },
    // ダッシュ挿入
    insertDash() {

      // #bodyのみ
      if ('body' != document.getSelection().focusNode.parentElement.id) {
        return;
      }
      let focusOffset = document.getSelection().focusOffset;
      let node = document.getSelection().anchorNode;

      let textArray = node.data.split('');
      textArray.splice(focusOffset, 0, '――');
      node.data = textArray.join('');

      this.$refs.body.focus();

      let range = document.getSelection().getRangeAt(0)
      range.setStart(document.getSelection().anchorNode, focusOffset + 2)
    },
    updateBodyCount() {
      this.bodyCount = this.$refs.body.innerText.replace(/\n/g, '').replace(/\s+/g, '').length;
    },
    // テキストファイル読み取り
    loadFile(body) {
      this.$refs.body.innerText = body;

      // 文字数リセット
      this.updateBodyCount();
      // 最初の文字数を記録
      this.initialBodyCount = this.bodyCount;
    },
    getShareEditorUrl() {
      let trackIndex = TrackSelect.data().tracks.findIndex((track) => {
        return track.filename === this.playingTrack.filename;
      });
      let imageIndex = ImageSelect.data().images.findIndex((image) => {
        return image.filename === this.selectingImage.filename;
      });

      let queries = querystring.stringify({
        track: trackIndex,
        image: imageIndex,
        horizontalMode: this.horizontalMode,
        fontSize: this.fontSize,
        baseOpacity: this.baseOpacity,
      })

      let shareText = '私はこの設定で執筆しています！';
      if (true == this.playingTrack.volumeAttention) {
        shareText += '（※音量注意）'
      }

      let url = location.href.split('?')[0];

      return 'https://twitter.com/intent/tweet?text=' + shareText + '｜いろはエディタ%0a&hashtags=いろはエディタ&url=' + url + '?' + encodeURIComponent(queries)
    },
    getShareSintyokuUrl() {
      let url = location.href.split('?')[0];
      return 'https://twitter.com/intent/tweet?text=' + this.minutes + '分間で【' + (this.bodyCount - this.initialBodyCount) + '文字】執筆しました！｜いろはエディタ%0a&hashtags=小説進捗&url=' + url;
    },
    getMailToParam()
    {
      let body = '';

      // エラー回避
      if (this.$refs.body) {
        body = this.$refs.body.innerText;
        body = body.replace(/\n/g, "%0D%0A");
        body = body.replace(/ /g, "%20");
      }

      return 'mailto:?body=' + body;
    },
    // 文字数リセット
    resetInitialBodyCount()
    {
      this.initialBodyCount = this.bodyCount;
    }

  }
}


</script>

<style lang="scss">

body {
  background: url("/images/bg1.jpeg") center center fixed no-repeat #000;
  background-size: cover;
  height: 700px;
  /* font-family: "M PLUS 1p"; */
  /* font-family: "Hannari"; */
  font-family: serif;
  overflow-x: hidden;
}

.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}


#body {
  width: 100%;
  height: 550px;
  border: none;
  font-family: serif;
  font-size: 18px;
  color: #e7dddb;
  outline: none;
  letter-spacing: .05em;
  line-height: 2;
  //background: rgba(0, 0, 0, .6);
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: left;
}

// 縦書きモード
#body.vertical {
  writing-mode: vertical-rl;
  overflow-x: scroll;
  padding: 20px;
  text-orientation: upright;
}

// 横書きモード
#body.horizontal {
  writing-mode: horizontal-tb;
  overflow-y: scroll;
  padding: 30px;
}

#body:not(.opacity0) {
  animation: fadein 3s;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.topButtons {
  margin-bottom: 30px;
  padding-top: 10px;
  vertical-align: middle;
  display: flex;
}

audio {
  width: 300px;
  height: 30px;
  margin-left: auto;
  opacity: .6;
}

.topButtons button:not(.buttonShareEditorUrl) {
  display: inline-block;
  font-size: 80%;
  color: #ccc;
  border: 2px solid #ccc;
  background: none;
  padding: 3px 6px;
  transition: opacity .5s;
  border-radius: 6px;
  margin-right: 10px;
}

.buttonTrackChange {
  margin-left: 20px;
}

.buttonImageChange {
}

.buttonOption {
  margin-right: 20px;
}

.buttonShareEditorUrl {
  display: inline-block;
  //margin-left: 20px;
  font-size: 14px;
  color: #ccc;
  //border: 2px solid transparent;
  border: none;
  background: none;
  //padding: 3px 6px;
  transition: opacity .5s;
  //border-radius: 6px;
  margin: 7px 10px 0 0;
  padding: 0;
}

/*.displayNone {*/
/*  display: none;*/
/*}*/

.opacity0 {
  opacity: 0;
}

@keyframes timerAnime {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.coverAnime-enter-active, .coverAnime-leave-active {
  transition: all .5s;
  transform: scale(1);
}

.coverAnime-enter, .coverAnime-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: scale(1.05);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #ddd;
  /* background: linear-gradient(95deg,#f9d9e6,#c9f1f1); */
}

::-webkit-scrollbar-track {
  border-radius: 30px;
  //background: rgba(0, 0, 0, .6);
  background: transparent;
}


#firstCover {
  /*padding-top: 200px;*/
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #eee;
  letter-spacing: .2em;
  /*font-size: 200%;*/
  z-index: 1000;
  transition: bottom 1.4s;
  overflow: hidden;
}

.underButtons {
  flex-basis: 40%;
}

.underButtons button {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  margin-right: 15px;
  font-size: 100%;
  transition: opacity .5s;
}

button:hover {
  opacity: .5;
  cursor: pointer;
}

.buttonUndo, .buttonRedo {
  border-radius: 20px;
  padding: 6px;
}

.textEditButtons {
  border-radius: 10px;
  font-size: 80% !important;
  padding: 6px 8px;
}

.buttonRuby {
  padding-left: 2px !important;
  //padding-right: 9px !important;
}

.buttonHorizontalMode {
  border-radius: 10px;
  font-size: 90% !important;
  /*padding-left: 2px !important;*/
  padding: 6px 9px !important;
}

.buttonDownload {
  background: rgba(20, 20, 20, .7);
  color: #fff;
  border: none;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
  transition: opacity .5s;
  border-radius: 4px;
  font-size: 83%;
  padding: 4px 17px;
  letter-spacing: .1em;
}

.buttonDownload span {
  margin-left: 10px;
  display: inline-block;
}

.buttonMailTo {
  background: rgba(20, 20, 20, .7);
  color: #fff;
  border: none;
  margin-top: 10px;
  //margin-right: 15px;
  margin-bottom: 30px;
  //margin-left: 20px;
  transition: opacity .5s;
  border-radius: 4px;
  font-size: 83%;
  padding: 4px 17px;
  letter-spacing: .1em;
  text-decoration: none;
  display: inline-block;
}

.timer {
  flex-basis: 70%;
  color: #eee;
  position: relative;
  animation: timerAnime 3s infinite;
}

.timer .minutes {
  opacity: 1;
  position: absolute;
  display: block;
  right: 0;
  transition: opacity .5s;
}

.timer .sintyoku {
  opacity: 0;
  position: absolute;
  display: block;
  right: 0;
  transition: opacity .5s;
}

.timer:hover .minutes {
  opacity: 0 !important;
  position: absolute;
}

.timer:hover .sintyoku {
  opacity: 1;
  position: absolute;
  cursor: pointer;
  letter-spacing: .1em;
}

.timer .sintyoku a {
  color: #fff;
  text-decoration: none;
}

</style>
