<template>
  <div class="cover">

    <div class="buttonClose" @click="$emit('close')">
      <a href="#" @click.prevent>
        <font-awesome-icon icon="times-circle"/>
      </a>
    </div>
    <h3>
      image list
    </h3>

    <ul class="imageList">
      <li v-for="(image, index) in images" :key="index" :class="{'selectingImage':image.filename == selectingImage.filename, 'isNew':true == image.isNew}">
        <a :href="'images/' + image.filename" @click.prevent="imageChange(image)">
          <img :src="'images/thum/' + image.filename" alt="">
        </a>
      </li>
    </ul>

    <ul class="materialList">
      <h4>image by</h4>
      <li><a href="https://pakutaso.com" target="_blank">PAKUTASO</a></li>
      <li><a href="https://min-chi.material.jp/" target="_blank">みんちりえ</a></li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "ImageSelect",
  props: {
    selectingImage: {
      type: Object
    }
  },
  data() {
    return {
      images: [
        {isNew: false, filename: 'bg1.jpeg'},
        {isNew: false, filename: 'bg2.jpeg'},
        {isNew: false, filename: 'bg3.jpeg'},
        {isNew: false, filename: 'bg4.jpeg'},
        {isNew: false, filename: 'bg5.jpeg'},
        {isNew: false, filename: 'bg6.jpeg'},
        {isNew: false, filename: 'bg7.jpeg'},
        {isNew: false, filename: 'bg8.jpeg'},
        {isNew: false, filename: 'bg9.jpeg'},
        {isNew: false, filename: 'bg10.jpeg'},
        {isNew: false, filename: 'bg11.jpeg'},
        {isNew: false, filename: 'bg12.jpeg'},
        {isNew: false, filename: 'bg13.jpeg'},
        {isNew: false, filename: 'bg14.jpeg'},
        {isNew: false, filename: 'bg15.jpeg'},
        {isNew: false, filename: 'bg16.jpeg'},
        {isNew: false, filename: 'bg17.jpeg'},
        {isNew: false, filename: 'bg18.jpeg'},
        {isNew: false, filename: 'bg19.jpeg'},
        {isNew: false, filename: 'bg20.jpeg'},
        {isNew: false, filename: 'bg21.jpeg'},
        {isNew: false, filename: 'bg22.jpeg'},
        {isNew: false, filename: 'bg23.jpeg'},
        {isNew: false, filename: 'bg24.jpeg'},
        {isNew: false, filename: 'bg25.jpeg'},
        {isNew: false, filename: 'bg26.jpeg'},
        {isNew: false, filename: 'bg27.jpeg'},
        // {isNew: false, filename: 'bg28.jpeg'},
        // {isNew: false, filename: 'bg29.jpeg'},
        // {isNew: false, filename: 'bg30.jpeg'},
        // {isNew: false, filename: 'bg31.jpeg'},
        // {isNew: false, filename: 'bg32.jpeg'},
        // {isNew: false, filename: 'bg33.jpeg'},
        // {isNew: false, filename: 'bg34.jpeg'},
        // {isNew: false, filename: 'bg35.jpeg'},
        // {isNew: false, filename: 'bg36.jpeg'},
        // {isNew: false, filename: 'bg37.jpeg'},
        // {isNew: false, filename: 'bg38.jpeg'},
        // {isNew: false, filename: 'bg39.jpeg'},
        // {isNew: false, filename: 'bg40.jpeg'},
        // {isNew: false, filename: 'bg41.jpeg'},
        // {isNew: false, filename: 'bg42.jpeg'},
        // {isNew: false, filename: 'bg43.jpeg'},
        // {isNew: false, filename: 'bg44.jpeg'},
        // {isNew: false, filename: 'bg45.jpeg'},
        // {isNew: false, filename: 'bg46.jpeg'},
        // {isNew: false, filename: 'bg47.jpeg'},
        // {isNew: false, filename: 'bg48.jpeg'},
        // {isNew: false, filename: 'bg49.jpeg'},
        // {isNew: false, filename: 'bg50.jpeg'},
        // {isNew: false, filename: 'bg51.jpeg'},
        // {isNew: false, filename: 'bg52.jpeg'},
        // {isNew: false, filename: 'bg53.jpeg'},
        // {isNew: false, filename: 'bg54.jpeg'},
        // {isNew: false, filename: 'bg55.jpeg'},
        // {isNew: false, filename: 'bg56.jpeg'},
        // {isNew: false, filename: 'bg57.jpeg'},
        // {isNew: false, filename: 'bg58.jpeg'},
        // {isNew: false, filename: 'bg59.jpeg'},
        // {isNew: false, filename: 'bg60.jpeg'},
        // {isNew: false, filename: 'bg61.jpeg'},
        // {isNew: false, filename: 'bg62.jpeg'},
        // {isNew: false, filename: 'bg63.jpeg'},
        // {isNew: false, filename: 'bg64.jpeg'},
        // {isNew: false, filename: 'bg65.jpeg'},
        // {isNew: false, filename: 'bg66.jpeg'},
        // {isNew: false, filename: 'bg67.jpeg'},
        // {isNew: false, filename: 'bg68.jpeg'},
        // {isNew: false, filename: 'bg69.jpeg'},
        // {isNew: false, filename: 'bg70.jpeg'},
        // {isNew: false, filename: 'bg71.jpeg'},
        // {isNew: false, filename: 'bg72.jpeg'},
        // {isNew: false, filename: 'bg73.jpeg'},
        // {isNew: false, filename: 'bg74.jpeg'},
        // {isNew: false, filename: 'bg75.jpeg'},
        // {isNew: false, filename: 'bg76.jpeg'},
        // {isNew: false, filename: 'bg77.jpeg'},
        // {isNew: false, filename: 'bg78.jpeg'},
        // {isNew: false, filename: 'bg79.jpeg'},
        // {isNew: false, filename: 'bg80.jpeg'},
        // {isNew: false, filename: 'bg81.jpeg'},
        // {isNew: false, filename: 'bg82.jpeg'},
        // {isNew: false, filename: 'bg83.jpeg'},
        // {isNew: false, filename: 'bg84.jpeg'},
        // {isNew: false, filename: 'bg85.jpeg'},
        // {isNew: false, filename: 'bg86.jpeg'},
        // {isNew: false, filename: 'bg87.jpeg'},
        // {isNew: false, filename: 'bg88.jpeg'},
        // {isNew: false, filename: 'bg89.jpeg'},
        // {isNew: false, filename: 'bg90.jpeg'},
        // {isNew: false, filename: 'bg91.jpeg'},
        // {isNew: false, filename: 'bg92.jpeg'},
        // {isNew: false, filename: 'bg93.jpeg'},
        // {isNew: false, filename: 'bg94.jpeg'},
        // {isNew: false, filename: 'bg95.jpeg'},
        // {isNew: false, filename: 'bg96.jpeg'},
        // {isNew: false, filename: 'bg97.jpeg'},
        {isNew: false, filename: 'bg98.jpeg'},
        {isNew: false, filename: 'bg99.jpeg'},
        {isNew: false, filename: 'bg100.jpeg'},
        {isNew: false, filename: 'bg101.jpeg'},
        {isNew: false, filename: 'bg102.jpeg'},
        {isNew: false, filename: 'bg103.jpeg'},
        // {isNew: false, filename: 'bg104.jpeg'},
        // {isNew: false, filename: 'bg105.jpeg'},
        // {isNew: false, filename: 'bg106.jpeg'},
        // {isNew: false, filename: 'bg107.jpeg'},
        // {isNew: false, filename: 'bg108.jpeg'},
        // {isNew: false, filename: 'bg109.jpeg'},
        // {isNew: false, filename: 'bg110.jpeg'},
        // {isNew: false, filename: 'bg111.jpeg'},
        // {isNew: false, filename: 'bg112.jpeg'},
        // {isNew: false, filename: 'bg113.jpeg'},
        // {isNew: false, filename: 'bg114.jpeg'},
        // {isNew: false, filename: 'bg115.jpeg'},
        // {isNew: false, filename: 'bg116.jpeg'},
        // {isNew: false, filename: 'bg117.jpeg'},
        // {isNew: false, filename: 'bg118.jpeg'},
        // {isNew: false, filename: 'bg119.jpeg'},
        // {isNew: false, filename: 'bg120.jpeg'},
        // {isNew: false, filename: 'bg121.jpeg'},
        // {isNew: false, filename: 'bg122.jpeg'},
        // {isNew: false, filename: 'bg123.jpeg'},
        // {isNew: false, filename: 'bg124.jpeg'},
        // {isNew: false, filename: 'bg125.jpeg'},
        // {isNew: false, filename: 'bg126.jpeg'},
        // {isNew: false, filename: 'bg127.jpeg'},
        // {isNew: false, filename: 'bg128.jpeg'},
        // {isNew: false, filename: 'bg129.jpeg'},
        // {isNew: false, filename: 'bg130.jpeg'},
        // {isNew: false, filename: 'bg131.jpeg'},
        // {isNew: false, filename: 'bg132.jpeg'},
        // {isNew: false, filename: 'bg133.jpeg'},
        // {isNew: false, filename: 'bg134.jpeg'},
        // {isNew: false, filename: 'bg135.jpeg'},
        // {isNew: false, filename: 'bg136.jpeg'},
        // {isNew: false, filename: 'bg137.jpeg'},
        // {isNew: false, filename: 'bg138.jpeg'},
        // {isNew: false, filename: 'bg139.jpeg'},
        // {isNew: false, filename: 'bg140.jpeg'},
        // {isNew: false, filename: 'bg141.jpeg'},
        // {isNew: false, filename: 'bg142.jpeg'},
        // {isNew: false, filename: 'bg143.jpeg'},
        // {isNew: false, filename: 'bg144.jpeg'},
        // {isNew: false, filename: 'bg145.jpeg'},
        // {isNew: false, filename: 'bg146.jpeg'},
        // {isNew: false, filename: 'bg147.jpeg'},
        // {isNew: false, filename: 'bg148.jpeg'},
        // {isNew: false, filename: 'bg149.jpeg'},
        // {isNew: false, filename: 'bg150.jpeg'},
        // {isNew: false, filename: 'bg151.jpeg'},
        // {isNew: false, filename: 'bg152.jpeg'},
        // {isNew: false, filename: 'bg153.jpeg'},
        // {isNew: false, filename: 'bg154.jpeg'},
        // {isNew: false, filename: 'bg155.jpeg'},
        // {isNew: false, filename: 'bg156.jpeg'},
        {isNew: false, filename: 'bg157.jpeg'},
        {isNew: false, filename: 'bg158.jpeg'},
        {isNew: false, filename: 'bg159.jpeg'},
        {isNew: false, filename: 'bg160.jpeg'},
        {isNew: false, filename: 'bg161.jpeg'},
        {isNew: false, filename: 'bg162.jpeg'},
        {isNew: false, filename: 'bg163.jpeg'},
        // {isNew: false, filename: 'bg164.jpeg'},
        // {isNew: false, filename: 'bg165.jpeg'},
        // {isNew: false, filename: 'bg166.jpeg'},
        // {isNew: false, filename: 'bg167.jpeg'},
        // {isNew: false, filename: 'bg168.jpeg'},
        // {isNew: false, filename: 'bg169.jpeg'},
        // {isNew: false, filename: 'bg170.jpeg'},
        {isNew: true, filename: 'bg171.jpeg'},
        {isNew: true, filename: 'bg172.jpeg'},
        {isNew: true, filename: 'bg173.jpeg'},
        // {isNew: true, filename: 'bg174.jpeg'},
        {isNew: true, filename: 'bg175.jpeg'},
        // {isNew: true, filename: 'bg176.jpeg'},
        {isNew: true, filename: 'bg177.jpeg'},
        // {isNew: true, filename: 'bg178.jpeg'},
        {isNew: true, filename: 'bg179.jpeg'},
        {isNew: true, filename: 'bg180.jpeg'},
        // {isNew: true, filename: 'bg181.jpeg'},
        {isNew: true, filename: 'bg182.jpeg'},
        // {isNew: true, filename: 'bg183.jpeg'},
        {isNew: true, filename: 'bg184.jpeg'},
        {isNew: true, filename: 'bg185.jpeg'},
        {isNew: true, filename: 'bg186.jpeg'},
        {isNew: true, filename: 'bg187.jpeg'},
        {isNew: true, filename: 'bg188.jpeg'},
        {isNew: true, filename: 'bg189.jpeg'},
        {isNew: true, filename: 'bg190.jpeg'},
        // {isNew: true, filename: 'bg191.jpeg'},
        // {isNew: true, filename: 'bg192.jpeg'},
        // {isNew: true, filename: 'bg193.jpeg'},
        // {isNew: true, filename: 'bg194.jpeg'},
        // {isNew: true, filename: 'bg195.jpeg'},
        // {isNew: true, filename: 'bg196.jpeg'},
        // {isNew: true, filename: 'bg197.jpeg'},
        // {isNew: true, filename: 'bg198.jpeg'},
        // {isNew: true, filename: 'bg199.jpeg'},
        // {isNew: true, filename: 'bg200.jpeg'},
        // {isNew: true, filename: 'bg201.jpeg'},
        // {isNew: true, filename: 'bg202.jpeg'},
        // {isNew: true, filename: 'bg203.jpeg'},
        // {isNew: true, filename: 'bg204.jpeg'},
        // {isNew: true, filename: 'bg205.jpeg'},
        // {isNew: true, filename: 'bg206.jpeg'},
        // {isNew: true, filename: 'bg207.jpeg'},
        // {isNew: true, filename: 'bg208.jpeg'},
        // {isNew: true, filename: 'bg209.jpeg'},
        {isNew: true, filename: 'bg210.jpeg'},
        {isNew: true, filename: 'bg211.jpeg'},
        {isNew: true, filename: 'bg212.jpeg'},
        {isNew: true, filename: 'bg213.jpeg'},
      ]
    }
  },
  methods: {
    imageChange(image) {
      this.$emit('input', image);
    }
  }
}
</script>

<style scoped>

.cover {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #ddd;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 40px 60px;
  overflow-y: auto;
}

.cover::-webkit-scrollbar {
  width: 0;
}

a {
  color: #ddd;
  text-decoration: none;
}

a:hover {
  color: #aaa;
}

.imageList li {
  display: inline-block;
  margin: 10px;
}

.imageList li a {
  padding: 2px;
}

.imageList li img {
  width: 200px;
  border: 4px solid #333;
}
.imageList li.isNew img {
  border-color: #fff;
}
.imageList li.selectingImage img {
  border-color: #f00 !important;
}

.buttonClose {
  margin-left: auto;
  font-size: 160%;
  display: block;
  width: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
}

.imageList {
  line-height: 1.8;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}


.materialList {
  width: 200px;
  margin-left: auto;
  font-size: 90%;
  color: #aaa;
  letter-spacing: .1em;
}

.materialList h4 {
  margin-bottom: 10px;
}

.materialList li {
  list-style-position: inside;
  font-size: 85%;
  padding-left: 10px;
}

.materialList a {
  color: #aaa;
}

</style>